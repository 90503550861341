<template>
<div class="profile">
  <h5>{{ userProfile.name }}</h5>

  <div class="create-post">
    <h2>Add reminder</h2>
    <form class="addReminder--form" @submit.prevent>
      <textarea v-model.trim="post.content" placeholder="short description"></textarea>
      <label for="priority">Select priority:</label>
      <select name="priority" id="priority" v-model.trim="post.priority">
        <option value="" selected disabled>Select</option>
        <option value="p1">30 min. from now - 30 min. reminder</option>
        <option value="p2">60 min. from now - 60 min. reminder</option>
        <option value="p3">90 min. from now - 45 min. reminder</option>
        <option value="p4">120 min. from now - 60 min. reminder</option>
      </select>

      <label for="scope">Select scope:</label>
      <select name="scope" id="scope" v-model.trim="post.scope">
        <option value="" selected disabled>Select</option>
        <option value="Local">Local</option>
        <option value="Regional">Regional</option>
        <option value="National">National</option>
        <option value="Global">Global</option>
      </select>
      <p style="margin: 1rem 0 1rem 0;">
        <button @click="createPost()" :disabled="post.content === ''" class="button">
          Submit
        </button>
      </p>
    </form>
  </div>
</div>
</template>

<script>
import {
  commentsCollection,
  postsCollection
} from "@/config/firebase";
import {
  mapState
} from "vuex";
import moment from "moment";

export default {
  name: "AddReminder",
  components: {

  },
  data() {
    return {
      post: {
        content: "",
        priority: "",
        countdown: "",
        scope: "",
      },
      showCommentModal: false,
      selectedPost: {},
      showPostModal: false,
      fullPost: {},
      postComments: [],
      showWarningCSS: {},
      showExpiredCSS: {},
    };
  },
  created() {
    this.$store.dispatch("loadPosts");
  },
  computed: {
    ...mapState(["userProfile", "posts"]),
  },
  methods: {
    getWarningCSSRef(lookup) {
      // console.log('getWarningExpireCSSRef', lookup)
      return this.showWarningCSS[lookup] || false;
    },
    getExpireCSSRef(lookup) {
      // console.log('getWarningExpireCSSRef', lookup)
      return this.showExpiredCSS[lookup] || false;
    },
    setWarning(e, i) {
      // console.log('got event show warning', e)
      this.showWarningCSS = {
        ...this.showWarningCSS,
        [i]: e,
      };
    },
    setExpired(e, i) {
      // console.log('got event show Expired', i)
      // { ...state, visibilityFilter: action.filter }
      this.showExpiredCSS = {
        ...this.showExpiredCSS,
        [i]: e,
      };
    },
    parseYYYY(val) {
      return new Date(val).getFullYear();
    },
    parseMO(val) {
      return new Date(val).getMonth();
    },
    parseDD(val) {
      return new Date(val).getDate();
    },
    parseHH(val) {
      return new Date(val).getHours();
    },
    parseMM(val) {
      return new Date(val).getMinutes();
    },
    getProperDateByPriority(priority) {
      // console.log('priority', priority)
      const countdownPriority = {
        p1: moment().add(30, "m"),
        p2: moment().add(60, "m"),
        p3: moment().add(90, "m"),
        p4: moment().add(120, "m"),
        default: moment().add(30, "m"),
      };
      return countdownPriority[priority] || countdownPriority["default"];
    },
    getWarningByPriority(priority) {
      // console.log('priority', priority)
      const warningTimeoutPriority = {
        p1: 15,
        p2: 30,
        p3: 45,
        p4: 60,
        default: 15,
      };
      return (
        warningTimeoutPriority[priority] || warningTimeoutPriority["default"]
      );
    },
    createPost() {
      //  console.log('calcCountDown', this.getProperDateByPriority(this.post.priority))
      const cdown = this.getProperDateByPriority(this.post.priority);
      this.$store.dispatch("createPost", {
        content: this.post.content,
        priority: this.post.priority,
        warningTimout: this.getWarningByPriority(this.post.priority),
        scope: this.post.scope,
        countdown: moment(cdown).format("llll"),
      });
      this.post.content = "";
      this.post.priority = "";
      this.post.scope = "";
      this.$router.push('/');
    },
    toggleCommentModal(post) {
      this.showCommentModal = !this.showCommentModal;

      // if opening modal set selectedPost, else clear
      if (this.showCommentModal) {
        this.selectedPost = post;
      } else {
        this.selectedPost = {};
      }
    },
    likePost(id, likesCount) {
      this.$store.dispatch("likePost", {
        id,
        likesCount,
      });
    },
    async viewPost(post) {
      const docs = await commentsCollection
        .where("postId", "==", post.id)
        .get();

      docs.forEach((doc) => {
        let comment = doc.data();
        comment.id = doc.id;
        this.postComments.push(comment);
      });

      this.fullPost = post;
      this.showPostModal = true;
    },
    closePostModal() {
      this.postComments = [];
      this.showPostModal = false;
    },
    async deletePost(post) {
      if (
        window.confirm(
          `Ok to delete this reminder?`
        )
      ) {
        const docs = await postsCollection.doc(post.id).delete();
      } else {
        console.info("user canceled request to delete...");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  margin-top: 2rem;
}

.create-post {
  max-width: 50%;
  margin: 0 auto;
}

.addReminder--form {
  border: 1px solid gainsboro;
}
</style>
